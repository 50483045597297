import { IconType } from '@/components/icon';
import { IdentProviderConfig, IdentProviderType } from '.';
import { JsonObject } from '../../helpers';
import { BaseModel, CreateBaseModel } from '../base.model';
import { boolean, object, string } from 'yup';

export class IdentProviderModel extends BaseModel {
  id: string;
  type: IdentProviderType;
  typeLabel: string;
  iconSVG: string;
  icon?: IconType;
  name: string;
  enabled: boolean;
  lastUpdated: Date | null;
  identityProviderConfig: IdentProviderConfig;
  canWrite: boolean; // set to true to prevent the user from making changes to magic link and password providers
  optDisabled: boolean;

  // @todo to implement oidc doesn't have a prop that we can use as a hint
  // return !!this.identityProviderConfig.idpEntityId;
  readonly canEnable = true;

  schema = object({
    name: string().required('Name is required'),
    type: string().required('Type is required'),
    enabled: boolean().required('Enabled is required'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof IdentProviderModel, unknown>;

    this.id = (data.id as string) || '';
    this.type = (data.type as IdentProviderType) || '';
    this.typeLabel = '';
    this.iconSVG = '';
    this.name = (data.name as string) || '';
    this.enabled = !!data.enabled;
    this.lastUpdated = (data.lastUpdated as Date) || null;
    this.identityProviderConfig = (data.identityProviderConfig as IdentProviderConfig) || {};
    this.canWrite = false;
    this.optDisabled = false;
  }

  // extending model-type-specific classes should override this as-needed
  get typedProps(): JsonObject {
    return {};
  }

  get createType(): IdentProviderType {
    return this.type;
  }
}

export class CreateIdentProviderModel extends CreateBaseModel {
  id: string;
  name: string;
  type: IdentProviderType;
  enabled: boolean;
  identityProviderConfig: IdentProviderConfig;
  typedProps: JsonObject;

  constructor(identModel: IdentProviderModel) {
    super();

    this.id = identModel.id || '';
    this.name = identModel.name;
    this.type = identModel.type;
    this.enabled = identModel.enabled;
    this.identityProviderConfig = identModel.identityProviderConfig;
    this.typedProps = identModel.typedProps;
  }

  get _props(): Record<string, unknown> {
    const props = {
      id: this.id,
      name: this.name,
      type: this.type,
      enabled: this.enabled,
      identityProviderConfig: this.identityProviderConfig,
      ...this.typedProps,
    };

    return props;
  }
}
