import { JsonObject, UiOption } from '@/lib/helpers';
import { IdentProviderModel } from './ident-provider.model';
import { OidcIdentConfig, OidcIdentProviderModel } from './oidc-ident-provider.model';
import { cloneDeep } from 'lodash';
import { MagiclinkIdentProviderModel } from './magiclink-ident-provider.model';
import { PasswordIdentProviderModel } from './password-ident-provider.model';
import { SamlIdentConfig, SamlIdentProviderModel } from './saml-ident-provider.model';
import {
  SamlMetadataXmlIdentConfig,
  SamlMetadataXmlIdentProviderModel,
} from './saml-metadata-xml-ident-provider.model';
import {
  SamlMetadataUrlIdentConfig,
  SamlMetadataUrlIdentProviderModel,
} from './saml-metadata-url-ident-provider.model';

export type IdentProviderType =
  | 'OIDC'
  | 'SAML'
  | 'SAML-XML'
  | 'SAML-URL'
  | 'magicLink'
  | 'password';

export type IdentProviderConfig =
  | OidcIdentConfig
  | SamlIdentConfig
  | SamlMetadataXmlIdentConfig
  | SamlMetadataUrlIdentConfig;

export const IdentProviderMap: Map<IdentProviderType, IdentProviderModel> = new Map();
IdentProviderMap.set('OIDC', new OidcIdentProviderModel());
IdentProviderMap.set('SAML', new SamlIdentProviderModel());
IdentProviderMap.set('SAML-XML', new SamlMetadataXmlIdentProviderModel());
IdentProviderMap.set('SAML-URL', new SamlMetadataUrlIdentProviderModel());
IdentProviderMap.set('magicLink', new MagiclinkIdentProviderModel());
IdentProviderMap.set('password', new PasswordIdentProviderModel());

export const IdentProviderTypeOptions: UiOption[] = Array.from(IdentProviderMap.entries())
  .filter(([, model]) => !model.optDisabled)
  .map(([type, model]) => {
    return {
      label: model.typeLabel,
      value: type as string,
      disabled: !model.canWrite,
    };
  });

export const getTypedIdentProvider = <T extends IdentProviderModel>(
  model: IdentProviderModel
): T => {
  let compType = model.type;
  if (compType === 'SAML') {
    if ((model.identityProviderConfig as SamlMetadataUrlIdentConfig).samlMetadataUrl) {
      compType = 'SAML-URL';
    }

    if ((model.identityProviderConfig as SamlMetadataXmlIdentConfig).samlMetadataXML) {
      compType = 'SAML-XML';
    }
  }

  const typedModel = IdentProviderMap.get(compType);

  if (!typedModel) {
    return model as T;
  }

  const Constructor = typedModel.constructor as new (data?: JsonObject) => typeof typedModel;

  const identityProviderConfig = {
    ...typedModel.identityProviderConfig,
    ...model.identityProviderConfig,
  };

  const data = cloneDeep({ ...model._props, identityProviderConfig });

  return new Constructor(data) as T;
};
