import { object, string } from 'yup';
import { IdentProviderModel } from './ident-provider.model';
import { JsonObject } from '@/lib/helpers';
import SamlIcon from '@/assets/ident-icons/saml-icon.svg';
import { parse, isValid } from 'date-fns';

export type SamlIdentConfig = {
  knownIdpProvider: string | null;
  idpEntityId: string | null;
  idpSSOUrl: string | null;
  rawIdpCertificates: string[]; // this is just used when patching, it's not stored

  callbackUrl: string; // generated by auth backend
  spEntityId: string; // generated by auth backend
  idpCertificates: IdpCert[]; // this is the parsed version of rawIdpCertificates
};

export type IdpCert = {
  validFrom: string;
  validTo: string;
  x509Certificate: string;
  x509Fingerprint: string;
};

export class SamlIdentProviderModel extends IdentProviderModel {
  identityProviderConfig: SamlIdentConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    this.schema = this.schema.concat(
      object({
        identityProviderConfig: object({
          idpEntityId: string().required('Entity Id is required'),
          idpSSOUrl: string().url('Please enter a valid URL').required('SSO URL is required'),
          rawIdpCertificate: string().when('idpCertificates', {
            is: (idpCertificates: IdpCert[]) => !idpCertificates?.length,
            then: (schema) => schema.required('Please enter a valid certificate'),
            otherwise: (schema) => schema.optional(),
          }),
        }),
      })
    );

    const data = props as Record<keyof SamlIdentProviderModel, unknown>;

    this.type = 'SAML';
    this.typeLabel = 'SAML';
    this.iconSVG = SamlIcon;
    this.canWrite = true;

    this.identityProviderConfig = (data.identityProviderConfig as SamlIdentConfig) || {
      knownIdpProvider: null,
      idpEntityId: null,
      idpSSOUrl: '',
      callbackUrl: '',
      spEntityId: '',
      idpCertificates: [],
    };
  }

  get parsedCertificate(): IdpCert | null {
    return this.identityProviderConfig.idpCertificates?.[0] || null;
  }

  get certDates(): [Date | null, Date | null] {
    const { validTo, validFrom } = this.parsedCertificate || {};

    const fromDate = validFrom
      ? parse(
          String(validFrom).replace(/\s+/g, ' ').replace(/ GMT$/, '').trim(),
          'MMM d HH:mm:ss yyyy',
          new Date()
        )
      : null;
    const toDate = validTo
      ? parse(
          String(validTo).replace(/\s+/g, ' ').replace(/ GMT$/, '').trim(),
          'MMM d HH:mm:ss yyyy',
          new Date()
        )
      : null;

    return [isValid(fromDate) ? fromDate : null, isValid(toDate) ? toDate : null];
  }
}
