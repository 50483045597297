import {
  format as dateFnsFormat,
  getUnixTime,
  isValid,
  parseISO,
  fromUnixTime,
  getTime,
  startOfDay,
  subDays,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Fragment, ReactNode, cloneElement, isValidElement } from 'react';

export type JsonObject = Record<string, unknown>;

export type FlatObject = Record<string, string>;

export type LoadState = 'unloaded' | 'loading' | 'loaded';

export type ViewState = 'view' | 'edit' | 'add';

export type UiOption = {
  label: ReactNode;
  value: string;
  match?: boolean;
  disabled?: boolean;
  tooltip?: string;
  meta?: Record<string, unknown>;
  key?: string;
};

export enum DateFormat {
  MEDIUM = 'yyyy-MM-dd HH:mm',
  FULL = 'yyyy-MM-dd HH:mm:ss',
  FINE = 'yyyy-MM-dd HH:mm:ss.SSS',
  FRIEND = 'MMMM do, yyyy',
  NOTIME = 'yyyy-MM-dd',
}

export type NiceDate = {
  date: string;
  time: string;
  timeFull: string;
  formatted: string;
  dateObj: Date | null;
  timezone: string;
  offset: string;
};

export type DateVal = string | Date | number | null;

export type DateRangeValue = [Date | null, Date | null];

export const AWS_REGION_OPTS: UiOption[] = [
  { label: 'US', value: 'us', meta: { header: true } },
  { label: 'US East (Ohio)', value: 'us-east-2' },
  { label: 'US East (N. Virginia)', value: 'us-east-1' },
  { label: 'US West (N. California)', value: 'us-west-1' },
  { label: 'US West (Oregon)', value: 'us-west-2' },
  { label: 'Africa', value: 'africa', meta: { header: true } },
  { label: 'Africa (Cape Town)', value: 'af-south-1' },
  { label: 'Asia', value: 'asia', meta: { header: true } },
  { label: 'Asia Pacific (Hong Kong)', value: 'ap-east-1' },
  { label: 'Asia Pacific (Hyderabad)', value: 'ap-south-2' },
  { label: 'Asia Pacific (Jakarta)', value: 'ap-southeast-3' },
  { label: 'Asia Pacific (Malaysia)', value: 'ap-southeast-5' },
  { label: 'Asia Pacific (Melbourne)', value: 'ap-southeast-4' },
  { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
  { label: 'Asia Pacific (Osaka)', value: 'ap-northeast-3' },
  { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
  { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
  { label: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
  { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
  { label: 'Canada', value: 'canada', meta: { header: true } },
  { label: 'Canada (Central)', value: 'ca-central-1' },
  { label: 'Canada West (Calgary)', value: 'ca-west-1' },
  { label: 'Europe', value: 'europe', meta: { header: true } },
  { label: 'Europe (Frankfurt)', value: 'eu-central-1' },
  { label: 'Europe (Ireland)', value: 'eu-west-1' },
  { label: 'Europe (London)', value: 'eu-west-2' },
  { label: 'Europe (Milan)', value: 'eu-south-1' },
  { label: 'Europe (Paris)', value: 'eu-west-3' },
  { label: 'Europe (Spain)', value: 'eu-south-2' },
  { label: 'Europe (Stockholm)', value: 'eu-north-1' },
  { label: 'Europe (Zurich)', value: 'eu-central-2' },
  { label: 'Middle East', value: 'middle east', meta: { header: true } },
  { label: 'Israel (Tel Aviv)', value: 'il-central-1' },
  { label: 'Middle East (Bahrain)', value: 'me-south-1' },
  { label: 'Middle East (UAE)', value: 'me-central-1' },
  { label: 'South America', value: '', meta: { header: true } },
  { label: 'South America (São Paulo)', value: 'sa-east-1' },
];

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getRandomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getIdFromLabel = (label: string): string =>
  String(label)
    .replace(/ +/g, '-')
    .replace(/[^A-Za-z0-9-]+/, '')
    .toLowerCase();

export const getDateObj = (val: DateVal): Date | null => {
  if (!val) {
    return null;
  }

  if (val instanceof Date) {
    return val;
  }

  let dateObj: Date | null = null;

  if (String(val).match(/^[0-9]+$/) || typeof val === 'number') {
    const valSeconds = String(val).length === 13 ? Math.floor(Number(val) / 1000) : Number(val); // convert ms timestamps to s timestamps
    dateObj = fromUnixTime(valSeconds);
  } else {
    dateObj = parseISO(val);
  }

  if (isValid(dateObj)) {
    return dateObj;
  }

  return null;
};

export const getDaysAgoDate = (daysAgo: number): Date => {
  return startOfDay(subDays(new Date(), daysAgo));
};

export const getTimestamp = (val: DateVal, useMs = false): number | null => {
  const dateObj = getDateObj(val);
  if (!dateObj) {
    return null;
  }

  return useMs ? getTime(dateObj) : getUnixTime(dateObj);
};

export const getNiceNumber = (val: number, options?: { decimalPlaces?: number }): string => {
  const { decimalPlaces } = options || {};

  const safeD = typeof decimalPlaces === 'undefined' ? 0 : Number(decimalPlaces);

  // toLocaleString adds the thousands separator in the default locale, and the min/max options limit the decimal places
  const localeOpts = {
    minimumFractionDigits: safeD,
    maximumFractionDigits: safeD,
  };

  const localVal = val.toLocaleString(undefined, localeOpts);

  // prevent display of negative sign when value is zero (i.e. when -0.34 is truncated to -0)
  if (localVal.match(/^-0.?0*$/)) {
    return '0';
  }

  return localVal;
};

export const getNiceDate = (val: DateVal, format?: DateFormat, timezone?: string): NiceDate => {
  const niceDate: NiceDate = {
    date: '',
    time: '',
    timeFull: '',
    formatted: '',
    dateObj: null,
    timezone: '',
    offset: '',
  };
  let dateObj = getDateObj(val);

  if (!dateObj) {
    return niceDate;
  }

  if (timezone) {
    dateObj = getTimezoneDateObj(dateObj);

    if (!dateObj) {
      return niceDate;
    }
  }

  niceDate.dateObj = dateObj;
  niceDate.date = dateFnsFormat(dateObj, 'yyyy-MM-dd');
  niceDate.time = dateFnsFormat(dateObj, 'HH:mm');
  niceDate.timeFull = dateFnsFormat(dateObj, 'HH:mm:ss');
  niceDate.formatted = dateFnsFormat(dateObj, format || DateFormat.MEDIUM);
  niceDate.timezone = timezone || '';
  niceDate.offset = timezone ? dateFnsFormat(dateObj, 'XXX') : '';

  return niceDate;
};

export const getTimezoneDateObj = (val: DateVal, timezone?: string): Date | null => {
  const dateObj = getDateObj(val);

  if (!dateObj) {
    return null;
  }

  const targetTimezone = timezone || getUserTimezone().timezone;

  return utcToZonedTime(dateObj, targetTimezone) || null;
};

export const getUserTimezone = (): { timezone: string; offset: string } => {
  try {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: dateFnsFormat(new Date(), 'XXX'),
    };
  } catch {
    return {
      timezone: '',
      offset: '',
    };
  }
};

export const getFirstLastName = (name: string): { first: string; last: string } => {
  const nameParts = name.split(' ');
  const first = nameParts.shift() || '';
  return { first, last: nameParts.join(' ') || '' };
};

export const joinReactNodes = (nodes: ReactNode[], glue: ReactNode): ReactNode[] => {
  const joinedNodes: ReactNode[] = [];
  const GlueEl = isValidElement(glue) ? glue : <Fragment>{glue}</Fragment>;

  nodes.forEach((node, index) => {
    const NodeEl = isValidElement(node) ? node : <Fragment>{node}</Fragment>;
    joinedNodes.push(cloneElement(NodeEl, { key: `node-${index}` }));
    if (index < nodes.length - 1) {
      joinedNodes.push(cloneElement(GlueEl, { key: `glue-${index}` }));
    }
  });
  return joinedNodes;
};

export const downloadCsv = (data: string | string[][], filename: string) => {
  let csvData = '';

  if (typeof data === 'string') {
    csvData = data;
  }

  if (Array.isArray(data)) {
    const csvRows: string[] = [];

    data.forEach((row) => {
      const csvRow = row.map((val) => `"${String(val).replace(/"+/g, '\\"')}"`);
      csvRows.push(csvRow.join(','));
    });

    csvData = csvRows.join('\r\n');
  }

  const blob = new Blob([csvData], { type: 'text/csv' });

  return downloadFile(blob, filename);
};

// https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
export const downloadFile = (blobData: Blob, filename: string) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

// https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string
export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const removePrefix = (str: string, prefix: string): string =>
  String(str).replace(new RegExp(`^${prefix}`), '');

export const printConsoleBanner = () => {
  const message = `
 ____                 ____       _   _          _    ___     ___              
/ ___| _   _ _ __ ___|  _ \\ __ _| |_| |__      / \\  |_ _|   |_ _|_ __   ___   
\\___ \\| | | | '__/ _ \\ |_) / _\` | __| '_ \\    / _ \\  | |     | || '_ \\ / __|  
 ___) | |_| | | |  __/  __/ (_| | |_| | | |  / ___ \\ | | _   | || | | | (__ _ 
|____/ \\__,_|_|  \\___|_|   \\__,_|\\__|_| |_| /_/   \\_\\___( ) |___|_| |_|\\___(_)
                                                        |/                      

Come build with us! => human@surepath.ai                                                     
  `;

  console.log(message);
};
