import { Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import { findAll, getOrgFilter } from './atlas-data-api.service';
import { DateRangeValue, getDaysAgoDate, getTimestamp, JsonObject, UiOption } from '../helpers';
import { get, post } from './sp-api.service';
import { endOfDay, startOfDay } from 'date-fns';
import { UserEventModel } from '../models/user-event/user-event.model';

const COLLECTION = 'requests';

type SearchFilter = {
  keyword?: string;
  users?: string[];
  decisions?: number[];
  violations?: string[];
  model?: string;
  date?: {
    start?: number;
    end?: number;
  };
  riskScore?: string[];
};

export const getUserEvents = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<UserEventModel>> => {
  const filterParams: SearchFilter = {};

  if (dtFilter) {
    const { keyword, values } = dtFilter;

    if (keyword) {
      filterParams.keyword = keyword;
    }

    values.forEach(({ columnName, value }) => {
      switch (columnName) {
        case 'startTime':
          {
            const [startTime, endTime] = value as DateRangeValue;
            const startTimestamp = startTime ? getTimestamp(startOfDay(startTime), true) : null;
            const endTimestamp = endTime ? getTimestamp(endOfDay(endTime), true) : null;

            if (startTimestamp || endTimestamp) {
              const dateFilter: { start?: number; end?: number } = {};

              if (startTimestamp) {
                dateFilter.start = startTimestamp;
              }

              if (endTimestamp) {
                dateFilter.end = endTimestamp;
              }

              filterParams.date = dateFilter;
            }
          }
          break;
        case 'action':
          {
            const safeValue = Array.isArray(value) ? value : [value];
            filterParams.decisions = safeValue.map((val) => Number(val));
          }
          break;
        case 'model':
          filterParams.model = value as string;
          break;
        case 'violation':
          filterParams.violations = value as string[];
          break;
        case 'user':
          if (!filterParams.users) {
            filterParams.users = [];
          }

          filterParams.users.push((value as UiOption).value);
          break;
        case 'riskScore':
          filterParams.riskScore = value as string[];
          break;
      }
    });
  }

  // Max allowed look-back period is 30 days
  const maxLookbackTime = getTimestamp(getDaysAgoDate(30), true)!;
  if (!filterParams.date) {
    filterParams.date = { start: 0 };
  }

  if (!filterParams.date.start || filterParams.date.start < maxLookbackTime) {
    filterParams.date.start = maxLookbackTime;
  }

  const result = await post('/user/activity', {
    page: page + 1,
    pageSize,
    count: true,
    sort: [dtSort ? { [dtSort.columnName]: dtSort.direction } : undefined],
    match: filterParams,
  });

  const { data, count } = (result as JsonObject) || {};

  if (!Array.isArray(data)) {
    return {
      page,
      pageSize,
      rows: [],
      total: 0,
    };
  }

  const rows: UserEventModel[] = data.map((row) => new UserEventModel(row as JsonObject));

  return {
    page,
    pageSize,
    rows,
    total: (count as number) || 0,
  };
};

export const getUserEventById = async (eventId: string): Promise<UserEventModel | null> => {
  const response = await get(`/user/activity/${eventId}`);
  return response ? new UserEventModel(response as JsonObject) : null;
};

export const getLatestUserEvent = async (
  conversationId: string
): Promise<UserEventModel | null> => {
  const params = {
    filter: {
      ...getOrgFilter(),
      conversationId,
    },
    sort: { timestamp: -1 },
    limit: 1,
  };

  const response = await findAll(COLLECTION, params);
  return response.length ? new UserEventModel(response[0]) : null;
};
