import { sortBy } from 'lodash';
import { DEFAULT_PAGE_SIZE, Dataset, DtSort } from '@/components/data-table/helpers';
import { JsonObject } from '@/lib/helpers';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import { DeleteResponse, InsertResponse, SAFE_LIMIT, UpdateResponse } from '@/lib/services';
import { get } from '@/lib/services/sp-api.service';
import {
  deleteOne,
  findAll,
  findAllAndCount,
  findOne,
  getOidParam,
  getOidParams,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
} from '@/lib/services/atlas-data-api.service';
import { getPrivateModelConnectors } from '@/lib/services/connector.service';
import { AwsConfig } from '../models/connector/aws-connector.model';
import { getPoliciesByModelIds } from '@/lib/services/policy.service';
import { AvailablePrivateModel } from '../models/available-private-model.model';
import { AuthProvider } from './auth.service';
import { getTypedPrivateModel, PrivateModelType } from '../models/private-model';
import { AwsPrivateModelConfig } from '../models/private-model/aws-private-model.model';
import { ConnectorModel } from '../models/connector/connector.model';
import { PolicyModel } from '../models/policy.model';
import { AgentModel } from '../models/agent.model';
import { getAgentsByModelIds } from './agent.service';

export const COLLECTION = 'privateModels';

export type ModelUsage = { policies: PolicyModel[]; agents: AgentModel[]; inUse: boolean };

export type ModelUsageMap = Map<string, ModelUsage>;

export const getAllPrivateModels = async () => (await getPrivateModels(0, SAFE_LIMIT)).rows;

export const getPrivateModels = async (
  page = 0,
  pageSize: number = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort
): Promise<Dataset<PrivateModelModel>> => {
  const sort = getSort(dtSort);
  const skip = page * pageSize;

  const { documents, total } = await findAllAndCount(
    COLLECTION,
    getOrgFilter(),
    pageSize,
    skip,
    sort
  );

  const rows: PrivateModelModel[] = documents.map((data) => new PrivateModelModel(data));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getAvailablePrivateModels = async (
  connectorIds?: string[]
): Promise<Dataset<AvailablePrivateModel>> => {
  const dataset: Dataset<AvailablePrivateModel> = {
    page: 0,
    pageSize: SAFE_LIMIT,
    rows: [],
    total: 0,
  };

  let targetConnectorIds = [...(connectorIds || [])];

  if (!targetConnectorIds.length) {
    const pmConnectors = await getPrivateModelConnectors();
    targetConnectorIds = pmConnectors
      .filter(({ status }) => status === 'active')
      .map(({ id }) => id);
  }

  if (!targetConnectorIds.length) {
    return dataset;
  }

  const response = (await get(`/orgs/${AuthProvider.orgId}/private-models`, {
    connectorIds: targetConnectorIds.join(','),
    page: 1,
    pageSize: 250,
  }).catch(() => ({
    privateModels: [],
  }))) as JsonObject;

  if (!response?.data) {
    return dataset;
  }

  const availableModels = ((response?.data || []) as JsonObject[]).map(
    (data) => new AvailablePrivateModel(data)
  );

  const filteredModels = availableModels.filter(({ inputModalities, outputModalities }) => {
    const hasTextInput = inputModalities.includes('text');
    const hasTextOutput = outputModalities.includes('text');
    return hasTextInput && hasTextOutput;
  });

  dataset.rows = sortBy(filteredModels, ['name']);
  dataset.total = filteredModels.length;

  return dataset;
};

export const getPrivateModelById = async (modelId: string): Promise<PrivateModelModel | null> => {
  const params = {
    filter: {
      _id: getOidParam(modelId),
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new PrivateModelModel(response) : null;
};

export const getPrivateModelsByIds = async (modelIds: string[]): Promise<PrivateModelModel[]> => {
  const params = {
    filter: {
      _id: { $in: getOidParams(modelIds) },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response.map((data) => new PrivateModelModel(data));
};

export const getPrivateModelsByConnectorId = async (
  connectorId: string
): Promise<PrivateModelModel[]> => {
  const params = {
    filter: {
      connectorId: getOidParam(connectorId),
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response.map((data) => new PrivateModelModel(data));
};

export const getModelUsage = async (modelIds: string[]): Promise<ModelUsageMap> => {
  const usage: ModelUsageMap = new Map();

  const [allPolicies, allAgents] = await Promise.all([
    getPoliciesByModelIds(modelIds),
    getAgentsByModelIds(modelIds),
  ]);

  modelIds.forEach((modelId) => {
    const policies = allPolicies.filter(
      ({ portal }) =>
        portal.defaultModelId === modelId || portal.availablePrivateModelIds.includes(modelId)
    );

    const agents = allAgents.filter(({ privateModelId }) => privateModelId === modelId);

    usage.set(modelId, { policies, agents, inUse: !!policies.length || !!agents.length });
  });

  return usage;
};

export const createPrivateModelFromConnector = async (
  model: PrivateModelModel,
  connector: ConnectorModel,
  modelId: string
): Promise<InsertResponse> => {
  const connectorType = connector.type;

  model.type = connectorType as PrivateModelType;
  const typedModel = getTypedPrivateModel(model);

  typedModel.providerModelProperties = {
    model: modelId,
    modelId: modelId || '', // @todo not needed, duplicate of top-level prop
  };

  return createPrivateModel(typedModel, connector);
};

export const createPrivateModelFromAvailable = async (
  model: PrivateModelModel,
  connector: ConnectorModel,
  selectedAvailableModel: AvailablePrivateModel
): Promise<InsertResponse> => {
  model.type = connector.type as PrivateModelType;
  const typedModel = getTypedPrivateModel(model);

  const { name, provider, service, modelId } = selectedAvailableModel || {};

  typedModel.service = service || '';
  typedModel.provider = provider || '';

  typedModel.providerModelProperties = {
    model: name,
    modelId: modelId || '', // @todo not needed, duplicate of top-level prop
  };

  return createPrivateModel(typedModel, connector);
};

export const createPrivateModel = async (
  model: PrivateModelModel,
  connector: ConnectorModel
): Promise<InsertResponse> => {
  // @todo move this into aws-private-model.model
  if (connector.type === 'aws') {
    const { awsRegion } = connector.config as AwsConfig;
    (model.providerModelProperties as AwsPrivateModelConfig).region = awsRegion || '';
  }

  const { orgId } = getOrgFilter();

  const document = {
    ...model._props,
    connectorId: getOidParam(model.connectorId),
    orgId,
  };

  const newModelId = await insertOne(COLLECTION, document);

  if (!newModelId) {
    return { inserted: false, error: 'The model could not be created' };
  }

  return { inserted: true, id: newModelId };
};

export const patchPrivateModel = async (modelId: string, name: string): Promise<UpdateResponse> => {
  const filter = {
    _id: { $oid: modelId },
    ...getOrgFilter(),
  };

  const response = await patchOne(COLLECTION, filter, { name });

  return { updated: response === null };
};

export const deletePrivateModel = async (modelId: string): Promise<DeleteResponse> => {
  const filter = {
    _id: { $oid: modelId },
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  return { deleted };
};
